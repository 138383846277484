<template>

  <h2>Connected applications</h2>

  <section>

    <template v-if="accountIntegrations.length === 0">
      You currently have no connected applications, which means Dispatch can’t send you any data or alerts!
      Get started by <router-link :to="{ name: 'PatchCreate', params: { referrerLink: 'connected-applications-empty-state' }}">creating a Patch</router-link>, or add an app below.
    </template>

    <template v-else>

      <h4>Authorized apps</h4>

      <div class="account-integrations">
        <div
          class="account-integration"
          :key="accountIntegration.id"
          v-for="accountIntegration in groupedAccountIntegrations"
        >
          <div class="header">
            <img :src="getIconUrl('action', accountIntegration.provider)" />
            <h5>{{ accountIntegration.name }}</h5>
            <span class="flex-grow" />
            <OverflowMenu :forceAlignRight="true">
              <template #trigger>
                <button class="small secondary"><EllipsisIcon /></button>
              </template>
              <button
                class="link"
                :disabled="isAPIReadOnly"
                @click="createPatch(accountIntegration.provider, accountIntegration)"
              >
                <CopyIcon /> Create Patch
              </button>
              <button
                :disabled="isAPIReadOnly"
                class="link delete-button"
                @click="openDeleteAccountIntegrationModal(accountIntegration)"
              >
                <TrashIcon /> {{ accountIntegration.provider === 'webhook' ? 'Delete' : 'Revoke access' }}
              </button>
            </OverflowMenu>
          </div>
          <ul>
            <li>
              <strong>Application:</strong> {{ titleCase(accountIntegration.provider) }}
            </li>
            <li v-if="accountIntegration.provider === 'webhook'">
              <strong>URL:</strong> <span class="break-all">{{ accountIntegration.defaultOutput }}</span>
            </li>
            <li v-else>
              <strong>Access:</strong> {{ permissionStrings[accountIntegration.provider] }}
            </li>
            <li><strong>Added:</strong> {{ formatTimestamp(accountIntegration.insertedAt) }}</li>
            <li v-if="associatedPatches[accountIntegration.id]">
              Currently used in <strong>{{ associatedPatches[accountIntegration.id].length }}</strong> Patch{{ associatedPatches[accountIntegration.id].length !== 1 ? 'es' : '' }}
            </li>
            <li v-else>
              Currently used in <strong>0</strong> Patches
            </li>
          </ul>
        </div>

      </div>

    </template>

  </section>

  <section>

    <h4>{{ accountIntegrations.length === 0 ? 'Connect some apps to get started:' : 'Connect more apps' }}</h4>

    <div class="create-buttons">

      <button :disabled="isAPIReadOnly" class="telegram-private" @click="addNewAccountIntegration('telegram-private')">
        <img :src="getIconUrl('action', 'telegram')" />
        <strong>Telegram</strong>
        <span>Private Chat</span>
      </button>

      <button :disabled="isAPIReadOnly" class="telegram-group" @click="addNewAccountIntegration('telegram-group')">
        <img :src="getIconUrl('action', 'telegram')" />
        <strong>Telegram</strong>
        <span>Group Chat</span>
      </button>

      <button :disabled="isAPIReadOnly" class="discord" @click="addNewAccountIntegration('discord-server')">
        <img :src="getIconUrl('action', 'discord')" />
        <strong>Discord</strong>
        <span>Server Channel</span>
      </button>

      <button :disabled="isAPIReadOnly" class="discord" @click="addNewAccountIntegration('discord-dm')">
        <img :src="getIconUrl('action', 'discord')" />
        <strong>Discord</strong>
        <span>Direct Message</span>
      </button>

      <button :disabled="isAPIReadOnly" class="webhook" @click="addNewAccountIntegration('webhook')">
        <img :src="getIconUrl('action', 'webhook')" />
        <strong>Webhook</strong>
      </button>

    </div>

  </section>

</template>

<script>

  import { mapState } from 'vuex'

  import useIcons from '@/composables/useIcons'
  import useFilters from '@/composables/useFilters'
  import useCreateAccountIntegration from '@/composables/useCreateAccountIntegration'

  import OverflowMenu from '@/components/utils/OverflowMenu.vue'

  import CopyIcon from '@/assets/icons/copy.svg'
  import TrashIcon from '@/assets/icons/trash.svg'
  import EllipsisIcon from '@/assets/icons/ellipsis.svg'

  export default {
    inject: ['$mixpanel'],
    components: {
      CopyIcon,
      TrashIcon,
      EllipsisIcon,
      OverflowMenu,
    },
    setup(props) {

      const { getIconUrl } = useIcons()
      const { titleCase, formatTimestamp } = useFilters()
      const { openCreateAccountIntegrationModal } = useCreateAccountIntegration()

      return {
        titleCase,
        getIconUrl,
        formatTimestamp,
        openCreateAccountIntegrationModal,
      }

    },
    computed: {
      ...mapState('user', ['accountIntegrations']),
      ...mapState('app', ['isAPIReadOnly', 'actionOptionsSlugMap']),
      groupedAccountIntegrations() {

        const groups = {}

        this.accountIntegrations.forEach((accountIntegration) => {
          groups[accountIntegration.provider] = groups[accountIntegration.provider] || []
          groups[accountIntegration.provider].push(accountIntegration)
        })

        return Object.values(groups).flat()

      },
    },
    data() {
      return {
        associatedPatches: {},
        permissionStrings: {
          telegram: 'Read and write messages',
          discord: 'Write messages in text channels',
        },
      }
    },
    created() {
      this.getAssociatedPatches()
    },
    methods: {
      getAssociatedPatches() {

        const promises = this.accountIntegrations.map((accountIntegration) => {

          this.associatedPatches[accountIntegration.id] = []

          // @TODO [V2]: move this logic to the delete modal
          return this.$store.state.api.dispatch
            .get(`/integrations/${accountIntegration.id}/associations`, { params: { size: 2 ** 32 } })
            .then((response) => {
              this.associatedPatches[accountIntegration.id] = response.data
            })

        })

        return Promise.all(promises)

      },
      openDeleteAccountIntegrationModal(accountIntegration) {
        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'DeleteAccountIntegrationModal',
          props: {
            accountIntegration,
            associatedPatches: this.associatedPatches[accountIntegration.id],
          },
        })
      },
      addNewAccountIntegration(type) {
        return this.openCreateAccountIntegrationModal(type)
          .catch(() => {
            // do nothing, a toast will be triggered from inside
            //  openCreateAccountIntegrationModal if there's an error
          })
      },
      createPatch(provider, accountIntegration) {

        const newTemplate = {}

        switch (provider) {

          case 'telegram': {
            newTemplate.telegramAccountIntegrationId = accountIntegration.id
            newTemplate.telegramActionEvent = accountIntegration.defaultOutput.startsWith('-') ? 'group' : 'private'
            break
          }

          case 'discord': {
            newTemplate.discordAccountIntegrationId = accountIntegration.id
            newTemplate.discordActionEvent = accountIntegration.serverId === null ? 'dm-channel' : 'server-channel'
            break
          }

          case 'webhook':
            newTemplate.webhookAccountIntegrationId = accountIntegration.id
            break

          default:
            return // do nothing

        }

        newTemplate.actionId = this.actionOptionsSlugMap[provider] ? this.actionOptionsSlugMap[provider].id : null

        this.$store.commit('forms/SET_FORM_TEMPLATE', {
          formName: 'createPatchForm',
          newTemplate,
        })

        this.$mixpanel.onReady((mixpanel) => {
          mixpanel.track('Account Integration "Create Patch" Button Clicked', {
            accountIntegration,
            newTemplate,
          })
        })

        this.$router.push({
          name: 'PatchCreate',
          params: {
            referrerLink: 'connected-applications-create-patch',
          },
        })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/account-page.styl'

  .account-integrations
    @apply grid
    @apply gap-4
    @apply grid-cols-1

    +breakpoint(lg)
      @apply grid-cols-2

  .account-integration
    @apply p-6
    @apply rounded

    @apply border
    @apply border-gray-500

  .delete-button
    @apply text-primary-500

    &:hover:not([disabled])
      @apply text-primary-500

  h4
    @apply text-gray-600

  .header
    @apply flex
    @apply items-start

    img
      @apply w-8
      @apply h-8
      @apply mr-2
      @apply flex-shrink-0

    h5
      @apply mr-4
      @apply mb-3
      @apply leading-8
      @apply break-normal

      overflow-wrap: anywhere

    button
      @apply h-8
      @apply px-2
      @apply min-h-0
      @apply text-sm
      @apply flex-shrink-0

  .create-buttons
    @apply grid
    @apply gap-4
    @apply grid-cols-1
    @apply justify-items-center

    +breakpoint(sm)
      @apply grid-cols-2

    +breakpoint(lg)
      @apply grid-cols-3

    button
      @apply p-6
      @apply w-full
      @apply text-lg
      @apply shadow-md
      @apply font-normal
      @apply text-gray-1000

      @apply flex
      @apply flex-col

      max-width: 16rem

      span
        @apply text-base
        @apply text-gray-800

      img
      svg
        @apply mb-2
        @apply w-16
        @apply h-16

      &.telegram-group
      &.telegram-private
        @apply bg-cyan-100

      &.discord
        @apply bg-grape-100

      &.webhook
        @apply bg-primary-100

</style>
